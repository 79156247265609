import { PersonalService } from './../services/api/personal/personal.service';
import { BaseClass } from './../../base-class';
import { Inject, Injectable, PLATFORM_ID, effect, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../storage/local.service';
import {
  HistorySearch,
  HomeIndex,
  MemberFamily,
  ShowBreadcrumb,
  ShowFooterMobile,
  TotalNewNoti,
  User,
  UserAuth,
} from './signals.model';
import { takeUntil } from 'rxjs';
import { HomeService } from '../services/api/home/home.service';
import { NotiService } from '../services/api/noti/news.service';

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  homeIndex = HomeIndex;
  user = User;
  userAuth = UserAuth;
  showFooterMobile = ShowFooterMobile;
  historySearch = HistorySearch;
  memberFamily = MemberFamily;
  totalNewNoti = TotalNewNoti;
  showBreadcrumb = ShowBreadcrumb;
  constructor(
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private baseClass: BaseClass,
    private personalService: PersonalService,
    private homeService: HomeService,
    private notiService: NotiService
  ) {
    this.refreshData();
    effect(() => {
      if (isPlatformBrowser(this.platformId)) {
      }
    });
  }
  refreshData() {
    let homeIndex = this.localStorageService.getItem('homeIndex');
    this.homeIndex.set(homeIndex ? homeIndex : {});
    let user = this.localStorageService.getItem('user');
    this.user.set(user ? user : {});
    let historySearch = this.localStorageService.getItem('historySearch');
    this.historySearch.set(historySearch ? historySearch : []);
    this.setShowFooterMobile(true);
    this.showBreadcrumb.set(true);
  }
  setHomeIndex(data: any) {
    // if (!this.localStorageService.getItem('homeIndex'))
    this.homeIndex.set(data);
    this.localStorageService.setItem('homeIndex', data);
  }
  updateHomeIndex() {
    if (this.baseClass.checkPlatform()) {
      this.homeService.getHomeIndex().subscribe((res: any) => {
        if (res.data) {
          this.setHomeIndex(res.data);
        }
      });
    }
  }
  updateUser() {
    if (this.baseClass.checkPlatform()) {
      this.personalService
        .getPersonalInfo()
        .pipe(takeUntil(this.baseClass.destroyed$))
        .subscribe((res: any) => {
          if (res.data) {
            if (res.data?.imageAvatarUrl)
              res.data.imageAvatarUrl =
                res.data?.imageAvatarUrl +
                `&token=${this.localStorageService.getItem('token')}`;
            this.setUser(res.data);
          }
        });
    }
  }
  setUser(data: any) {
    this.user.set(data);
    this.localStorageService.setItem('user', data);
  }
  setUserAuth(data: any) {
    this.userAuth.set(data);
    this.localStorageService.setItem('userAuth', data);
  }
  setShowFooterMobile(data: any) {
    this.showFooterMobile.set(data);
    this.localStorageService.setItem('showFooterMobile', data);
  }
  setHistorySearch(data: any) {
    this.historySearch.set(data);
    this.localStorageService.setItem('historySearch', data);
  }
  updateMemberFamily() {
    if (this.baseClass.checkPlatform()) {
      this.personalService
        .getMemberFamily()
        .pipe(takeUntil(this.baseClass.destroyed$))
        .subscribe((res: any) => {
          if (res.data?.length) {
            this.memberFamily.set(res.data);
          }
        });
    }
  }
  setTotalNewNoti(data: any) {
    this.totalNewNoti.set(data);
    this.localStorageService.setItem('totalNewNoti', data);
  }
  updateTotalNewNoti() {
    if (
      this.baseClass.checkPlatform() &&
      this.localStorageService.getItem('token')
    ) {
      this.notiService
        .getTotalNewNoti()
        .pipe(takeUntil(this.baseClass.destroyed$))
        .subscribe((res: any) => {
          if (res.succeeded) {
            this.setTotalNewNoti(res.data);
          }
        });
    }
  }
}
