import { SignalService } from './../../../signals/signals.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { HomeService } from '../home/home.service';
import { BaseService } from '../../base/base.service';

@Injectable({
  providedIn: 'root',
})
export class PersonalService extends BaseService {
  private sharedHeaders = new HttpHeaders();
  constructor(private http: HttpClient) {
    super();
    this.sharedHeaders = this.sharedHeaders.set(
      "Content-Type",
      "application/json"
    );
  }
  getPersonalInfo(): any {
    return this.http
      .get(`${this.apiDefault}/Personal/Index`)
      .pipe((res: any) => {
        if (res?.data) {
          console.log(res?.data);
        } else {
        }
        return res;
      }, catchError(this.handleError));
  }
  getAccountInfo(): any {
    return this.http.get<any>(`${this.apiDefault}/Personal/GetAccountInfo`, {
      headers: this.sharedHeaders
    });
  }
  getMemberFamily(): any {
    let params = {
      pageSize: 1000,
      pageIndex: 1,
    };
    return this.http.get<any>(`${this.apiDefault}/Personal/GetMemberFamily`, {
      headers: this.sharedHeaders,
      params
    });
  }
  memberFamilyDetail(params: any): any {
    return this.http.get<any>(`${this.apiDefault}/Personal/MemberFamilyDetail`, {
      headers: this.sharedHeaders,
      params
    });
  }
  updateUser(params: any = {}): any {
    return this.http.post<any>(`${this.apiDefault}/Personal/UpdateUser`, params, {
      headers: this.sharedHeaders
    });
  }
  createMemberFamily(params: any = {}): any {
    return this.http.post<any>(`${this.apiDefault}/Personal/CreateMemberFamily`, params, {
      headers: this.sharedHeaders
    });
  }
  updateMemberFamily(params: any = {}): any {
    return this.http.post<any>(`${this.apiDefault}/Personal/UpdateMemberFamily`, params, {
      headers: this.sharedHeaders
    });
  }
  changePassword(params: any = {}): any {
    return this.http.post<any>(`${this.apiDefault}/Account/ChangePassword`, params, {
      headers: this.sharedHeaders
    });
  }
  updateAvatar(file: File): any {
    let param: FormData = new FormData();
    param.append('avatar', file);
    return this.http.post<any>(`${this.apiDefault}/Personal/UploadAvatar`, param, {
      // headers: this.sharedHeaders
    });
  }
}
