import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "../../base/base.service";

@Injectable({
    providedIn: "root",
})
export class NotiService extends BaseService {
    private sharedHeaders = new HttpHeaders();
    constructor(private _http: HttpClient) {
        super();
        this.sharedHeaders = this.sharedHeaders.set(
            "Content-Type",
            "application/json"
        );
    }
    searchNotifies(params: any = {}): any {
        return this._http.post<any>(`${this.apiDefault}/NotifyController/SearchNotifies`, params, {
            headers: this.sharedHeaders,
        });
    }
    readNoti(params: any = {}): any {
        return this._http.post<any>(`${this.apiDefault}/NotifyController/ReadNoti`, params, {
            headers: this.sharedHeaders,
            params
        });
    }
    getTotalNewNoti(params: any = {}): any {
        return this._http.post<any>(`${this.apiDefault}/NotifyController/GetTotalNewNoti`, params, {
            headers: this.sharedHeaders,
        });
    }
    readAllNoti(params: any = {}): any {
        return this._http.post<any>(`${this.apiDefault}/NotifyController/ReadAllNoti`, params, {
            headers: this.sharedHeaders,
        });
    }
}
